export class Channel {
  id: number
  channel_name: string
  channel_id?: string
  refresh_token?: string
  refresh_token_retrieved_time?: string
  should_regrant_access?: boolean

  public constructor({
    id,
    channel_name,
    channel_id,
    refresh_token,
    refresh_token_retrieved_time,
    should_regrant_access,
  }) {
    this.id = id
    this.channel_name = channel_name
    this.channel_id = channel_id
    this.refresh_token = refresh_token
    this.refresh_token_retrieved_time = refresh_token_retrieved_time
    this.should_regrant_access = should_regrant_access
  }
}
