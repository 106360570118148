<template>
  <div>
    Loading...
  </div>
</template>

<script type="ts">
import Vue from 'vue'
import {mapActions} from "vuex";

export default Vue.extend({
  methods: {
    ...mapActions({
      googleRedirect: 'youtube/googleRedirect',
    }),
  },
  computed: {
    code() {
      return this.$route.query.code
    },
  },
  async mounted() {
    const currentRow = JSON.parse(localStorage.getItem('row_data'))
    if (this.code && currentRow) {
      console.log(this.code)
      const payload = {
        id: currentRow.id,
        channel_name: currentRow.channel_name,
        channel_id: currentRow.channel_id,
        code: this.code,
      }
      await this.googleRedirect(payload)
      await this.$router.push('/youtube/list')
    } else {
      await this.$router.push('/youtube/list')
      console.log('No code query')
    }
  },
})
</script>

<style lang="scss" scoped>
.inner {
  text-align: center;
}
</style>
