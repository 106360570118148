








































































import Vue from 'vue'
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import DeleteConfimationButton from '@/components/global/DeleteConfirmationButton.vue'
import { Channel } from '@/models/youtube/channel'

export default Vue.extend({
  components: {
    DeleteConfimationButton,
    Multiselect,
  },
  data() {
    return {
      loading: false,
      editData: {
        channel_name: '',
        channel_id: '',
      },
    }
  },
  props: {
    channel: {
      type: Object as () => Channel,
      default: () => {},
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  watch: {
    channel() {
      this.loadData()
    },
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.editData = this.isEditMode ? this.channel : {}
    },
    deleteChannel() {
      if (this.isEditMode) {
        this.$emit('delete-channel', this.channel)
      }
    },
    saveChannel() {
      const payload = { channel: this.editData }
      this.$emit('save-channel', payload)
    },
  },
})
