























































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default Vue.extend({
  data() {
    return {}
  },
  computed: {
    ...mapState({
      channelsData: (state: any) => state.youtube.channelsData,
      loading: (state: any) => state.youtube.loading,
    }),
  },
  methods: {
    ...mapActions({
      refreshTableData: 'youtube/fetchAndSetChannels',
      googleOAuth: 'youtube/googleOAuth',
    }),
    async regrantAccess(row) {
      localStorage.setItem('row_data', JSON.stringify(row))
      await this.googleOAuth()
    },
  },
  mounted() {
    this.refreshTableData()
  },
})
