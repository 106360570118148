import { YoutubeState } from './types'

export const initState = (): YoutubeState => ({
  loading: false,
  channelsData: [],
  channelsMeta: null,
  currentChannel: null,
})

export default initState
