








import DefaultLayout from './layouts/default.vue'
import NoLoginLayout from './layouts/nologin.vue'
import { mapState } from 'vuex'
require('./plugins')

export default {
  name: 'App',
  components: {
    DefaultLayout,
    NoLoginLayout,
  },
  //test
  computed: {
    ...mapState({
      isAuthenticated: (state: any) => state.auth.isAuthenticated,
    }),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
  },
}
