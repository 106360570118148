import { ActionContext, ActionTree } from 'vuex'
import axios from '../../services/axios'
import { RootState } from '../types'
import { YoutubeState } from './types'
import { Channel } from '../../models/youtube/channel'

interface YoutubeActionContext extends ActionContext<YoutubeState, RootState> {}

export const actions: ActionTree<YoutubeState, RootState> = {
  async fetchAndSetChannels({ commit, rootGetters }: YoutubeActionContext) {
    commit('setLoading', true)
    try {
      const { data } = await axios.get(`/settings?sort[0]=created_at&sortby[0]=desc`)
      commit('setChannelsList', data.data)
      commit('setDataLoading', false, { root: true })
      commit('setLoading', false)
    } catch (e) {
      commit('setLoading', false)
      console.log('fetchAndSetPage Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  async fetchChannelByName({ commit, rootGetters }: YoutubeActionContext, payload: { id: number }) {
    commit('setDataLoading', true, { root: true })
    try {
      const { data } = await axios.get(`/settings/${payload.id}`)
      commit('setCurrentChannel', data.data)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('fetchChannelById Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  createChannel: async (
    { rootGetters, commit }: ActionContext<YoutubeState, RootState>,
    payload: { channel: any }
  ) => {
    commit('setDataLoading', true, { root: true })
    try {
      const { data } = await axios.post(`/settings`, payload.channel)
      commit('setDataLoading', false, { root: true })
      return data
    } catch (e) {
      console.log('createChannel Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  updateChannel: async (
    { rootGetters, commit }: ActionContext<YoutubeState, RootState>,
    payload: { channel: any }
  ) => {
    commit('setDataLoading', true, { root: true })
    try {
      await axios.post(`/settings/`, payload.channel)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('updateChannel Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  deleteChannel: async (
    { rootGetters, commit }: ActionContext<YoutubeState, RootState>,
    payload: { channel: Channel }
  ) => {
    commit('setDataLoading', true, { root: true })
    try {
      await axios.delete(`/settings/` + payload.channel.id)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('deleteChannel Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  googleOAuth: async ({ rootGetters, commit }: ActionContext<YoutubeState, RootState>) => {
    commit('setDataLoading', true, { root: true })
    try {
      const { data } = await axios.get(`/auth/link`)
      window.open(data.data, '_self')
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('googleOAuth Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  googleRedirect: async (
    { rootGetters, commit }: ActionContext<YoutubeState, RootState>,
    payload
  ) => {
    commit('setDataLoading', true, { root: true })
    try {
      await axios.post(`/auth/code/`, payload)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('googleOAuth Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
}

export default actions
