import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/index.vue'
import Login from '../views/login.vue'
import YoutubeIndex from '../views/youtube/index'
import YoutubeNew from '../views/youtube/new'
import YoutubeEdit from '../views/youtube/edit/_id'
import ErrorPage from '../views/error.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/youtube/list',
    component: YoutubeIndex,
    name: 'YoutubeIndex',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/youtube/edit/:name',
    component: YoutubeEdit,
    name: 'YoutubeEdit',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/youtube/new',
    component: YoutubeNew,
    name: 'YoutubeNew',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    component: Index,
    name: 'Index',
    meta: {
      layout: 'noLoginLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: {
      layout: 'noLoginLayout',
      requiresAuth: false,
    },
  },
  {
    path: '/logout',
    component: Login,
    name: 'Logout',
    meta: {
      layout: 'noLoginLayout',
      requiresAuth: false,
    },
  },
  {
    path: '*',
    component: ErrorPage,
    name: 'Error',
    meta: {
      layout: 'noLoginLayout',
      requiresAuth: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/',
})

router.beforeEach(function(to, from, next) {
  store.dispatch('auth/localAuthenticate')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuthenticated) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else if (to.name === 'Logout') {
    store.dispatch('auth/logout')
    next({
      path: '/login',
    })
  } else {
    next()
  }
})

export default router
